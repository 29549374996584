import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1366,
    },
  },
  palette: {
    primary: {
      main: "#ffc800",
      light: "#fff",
    },
    secondary: {
      main: "#f2f3f3",
    },
    background: {
      default: "#fff",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            textTransform: "none",
            backgroundColor: "#ffc800",
            borderRadius: "24px",
            color: "#000",
            padding: "0.6rem 1.7rem",
            fontWeight: 700,
            minWidth: "240px",
            fontSize: "14px",
            gap:"24px",
            fontFamily:"Poppins, Helvetica, sans-serif",
            border: "2px solid transparent",
            paddingTop: "14px",
            paddingBottom: "14px",
            lineHeight:"16px",
            "&:hover": {
              backgroundColor: "#000000",
              color: "#ffffff",
              boxShadow: "none",
            },
            boxShadow: "none",
            "&:disabled": {
              backgroundColor: "#FFF1BF",
              color: "#000",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            textTransform: "none",
            backgroundColor: "#fff",
            borderRadius: "24px",
            border: "2px solid #000",
            color: "#000",
            padding: "0.6rem 1.7rem",
            fontWeight: 700,
            minWidth: "240px",
            fontSize: "14px",
            gap:"24px",
            fontFamily:"Poppins, Helvetica, sans-serif",
            paddingTop: "14px",
            paddingBottom: "14px",
            lineHeight:"16px",
            "&:hover": {
              border: "2px solid #000",
              backgroundColor: "#000000",
              color: "#ffffff",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            textTransform: "none",
            backgroundColor: "#d8d8d8",
            borderRadius: "24px",
            color: "#000",
            padding: "0.6rem 1.7rem",
            fontWeight: 700,
            fontSize: "14px",
            width: "100%",
            margin: "0.8rem",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
        },
      ],
    },
  },
});

export default theme;
